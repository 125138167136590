import React, {createContext, useContext, useState, useEffect} from "react";

import {APIContext} from "./APIContext";
import {AzureAuthContext} from "./AzureAuthContext";
import {ConfigContext} from "./ConfigContext";

export const UserContext = createContext({});

export const UserContextProvider = ({children}) => {
    const {searchFeature, editFeature, reportFeature, userAdminFeature, userActivityLogFeature} =
        useContext(ConfigContext);
    const [authorizationData, setAuthorizationData] = useState({});
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [isAuthorizationReady, setIsAuthorizationReady] = useState(false);
    const {isUserProfileDone, userProfile} = useContext(AzureAuthContext);
    const {ROLES, getUser} = useContext(APIContext);
    const FULL_ACCESS = [ROLES.SUPPORT, ROLES.ADMIN, ROLES.COMPLIANCE, ROLES.VIEW, ROLES.PMS]
    const readerRoles = searchFeature
        ? FULL_ACCESS
        : [];

    const editorRoles = editFeature ? [ROLES.ADMIN, ROLES.SUPPORT] : [];

    const reportRoles = reportFeature
        ? FULL_ACCESS
        : [];

    const userAdminRoles = userAdminFeature ? [ROLES.ADMIN] : [];

    const hasRoleIn = (roles) =>
        authorizationData?.role && roles.includes(authorizationData?.role?.name);
    const canAccessSearchModule = () => hasRoleIn([...readerRoles, ROLES.PMS]);
    const canEditPatient = () => hasRoleIn(editorRoles);
    const canEditSurgery = () => hasRoleIn(editorRoles);
    const canEditImplant = () => hasRoleIn(editorRoles);
    const canEditImplantWarranty = () =>
        hasRoleIn([ROLES.ADMIN, ROLES.SUPPORT]);

    const canAccessReportModule = () => hasRoleIn([...reportRoles, ROLES.PMS]);
    const canAccessCustomerCareReport = () => hasRoleIn(reportRoles);
    const canAccessDistributorReport = () => hasRoleIn(reportRoles);
    const canAccessImplantReport = () => hasRoleIn(reportRoles);
    const canAccessDeviceTrackingPMS = () => hasRoleIn([ROLES.ADMIN, ROLES.PMS]);
    const canAccessExplantInvestigationPMS = () => hasRoleIn([ROLES.ADMIN, ROLES.PMS]);

    const canAccessUserModule = () => hasRoleIn(userAdminRoles);
    const canAddUser = () => hasRoleIn(userAdminRoles);
    const canEditUser = () => hasRoleIn(userAdminRoles);
    const canViewUserActivityLog = () =>
        userActivityLogFeature && hasRoleIn(userAdminRoles);

    const canAccessSettingModule = () => hasRoleIn(userAdminRoles);

    useEffect(() => {
        if (isUserProfileDone)
            getUser(userProfile?.mail).then((data) => {
                setAuthorizationData(data?.user);
                setIsAuthorizationReady(true);
            });
    }, [isUserProfileDone, userProfile]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setIsAuthorized(hasRoleIn(Object.values(ROLES)));
    }, [authorizationData]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <UserContext.Provider
            value={{
                isAuthorized,
                isAuthorizationReady,
                canAccessSearchModule,
                canEditPatient,
                canEditSurgery,
                canEditImplant,
                canEditImplantWarranty,

                canAccessReportModule,
                canAccessCustomerCareReport,
                canAccessDistributorReport,
                canAccessImplantReport,
                canAccessDeviceTrackingPMS,
                canAccessExplantInvestigationPMS,

                canAccessUserModule,
                canAddUser,
                canEditUser,
                canViewUserActivityLog,

                canAccessSettingModule,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
